import React, {
    useCallback,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react";

import { Prompt } from "../Form/UI";
import { Spinner } from "../UI";
import { PreviewVideo, ItemsNotMoving, RecordStopButton, Crosshairs } from "./";
import {
    Time,
    VideoPlaceholder,
    StyledVideo,
    PromptText,
    PromptHeader,
    CancelBtn,
    SlowDown,
    MotionIndicator,
} from "./UI";
import { useSurveyStore } from "../../Stores/SurveyStore";
import { useTimer } from "../../Hooks";
import { useRootStore } from "../../Stores/RootStore";
import { useGyro } from "../../Hooks/useGyro";

const promptStyles = {
    position: "fixed",
    left: "10%",
    backgroundColor: "red",
    zIndex: 105,
};

// the best way i could find to manage the streams in order to release tracks before leaving the component
let streams = [];

function Video() {
    const RECORDING_SPEED_CUTOFF = 75;
    const RECORDING_SPEED_WARNNG = 50;
    const RECORDING_SPEED_THRESHOLD = 40;
    const { rootState } = useRootStore();
    const surveyStore = useSurveyStore();
    const abortController = useRef(null);
    const [time, startTimer, clearTimer] = useTimer();
    const [showSpinner, setShowSpinner] = useState(false);
    const recordError = useRef(null);
    const [videoState, dispatch] = useReducer(videoReducer, {
        confirmedPrompt: false,
        loading: true,
        displayPreview: false,
        isRecording: false,
    });
    const [itemsState, dispatchItem] = useReducer(itemsReducer, {
        showPrompt: false,
        items: [],
    });

    const [slowDown, setSlowDown] = useState(false);

    const [rotationRate, orientation, resetGyro] = useGyro();
    // handle the start and stop of recording video
    const recordVideo = () => {
        // start or stop the timer
        if (videoState.isRecording) {
            abortController.current.abort();
            // stop recording, triggering Promise.then below
            if (rootState.recorder.state === "recording") {
                rootState.recorder.stop();
            }
            dispatch({ type: "cancel-recording" });
            clearTimer();
        } else {
            // start recording...
            abortController.current = new AbortController();
            let signal = abortController.current.signal;
            startTimer();
            dispatch({ type: "start-recording" });

            const currentRoom = surveyStore.currentRoom;

            let videoStream = currentRoom.videoStream;

            // store current MediaRecorder in rootState
            rootState.setRecorder(new MediaRecorder(videoStream));
            // array to temporarily store recording data
            const data = [];
            rootState.recorder.ondataavailable = (event) => data.push(event.data);

            // start recorder
            rootState.recorder.start();
            // when recorder is stopped, resolve this promise
            const stopped = new Promise((resolve, reject) => {
                rootState.recorder.onstop = resolve;
                rootState.recorder.onerror = (event) => reject(event.name);

                const [matchedRoom] = surveyStore.roomList.filter(r => r.item === surveyStore.currentRoom.roomName)

                let timeframe = 10000;
                if (matchedRoom) {
                    timeframe = matchedRoom.timeframe * 1000;
                }

                wait(timeframe, signal)
                    .then(() => {
                        clearTimer();
                        if (rootState.recorder.state === "recording") {
                            rootState.recorder.stop();
                        }
                    })
                    .catch((err) => {
                        console.log("err");
                        console.log("recording canceled: ", err);
                        if (recordError.current !== null) {
                            // recording was stopped, but the user was probably moving too fast
                            // force them to re-record
                            resolve();
                        }
                    })
                    .finally(() => {
                        // release cloned MediaStream
                        videoStream.getTracks().forEach((t) => t.stop());
                        currentRoom.videoStream.getTracks().forEach((t) => t.stop());
                    });
            });

            stopped
                .then(() => {
                    if (!recordError.current) {
                        const recordedBlob = new Blob(data, { type: "video/mp4" });
                        currentRoom.streamSrc = URL.createObjectURL(recordedBlob);
                        currentRoom.recordedBlob = recordedBlob;
                        surveyStore.updateRooms(currentRoom);
                        dispatch({ type: "finished-recording" });
                    } else {
                        clearTimer();
                        rootState.setRecorder(null);
                        resetGyro();
                        if (recordError.current === "too-fast") {
                            dispatch({ type: "slow-down" });
                            recordError.current = null;
                            return;
                        }
                        if (recordError.current === "unsteady") {
                            dispatch({ type: "flat-line" });
                            recordError.current = null;
                            return;
                        }

                        recordError.current = null;
                        dispatch({ type: "reset" });
                    }
                })
                .catch((err) => {
                    console.log("recording canceled");
                });
        }
    };

    const wait = (delayInMS, signal) => {
        return new Promise((resolve, reject) => {
            let timeout = setTimeout(resolve, delayInMS);

            signal.addEventListener("abort", () => {
                clearTimeout(timeout);
                reject("promise canceled");
            });
        });
    };

    const videoRef = useCallback(
        async (videoElement) => {
            if (videoElement !== null) {
                if (window.navigator.mediaDevices) {
                    const stream = await window.navigator.mediaDevices.getUserMedia({
                        video: { facingMode: "environment" },
                        audio: true,
                    });
                    const devices =
                        await window.navigator.mediaDevices.enumerateDevices();
                    let allowsVideo = false;
                    for (let d of devices) {
                        if (d.kind === "videoinput" && d.label) {
                            allowsVideo = true;
                        }
                    }
                    if (allowsVideo) {
                        streams.push(stream);
                        const currentRoom = surveyStore.currentRoom;
                        videoElement.srcObject = stream;
                        if (currentRoom.videoStream) {
                            // release cloned MediaStream if exists
                            currentRoom.videoStream.getTracks().forEach((t) => t.stop());
                        }
                        // clone MediaStream for recording
                        currentRoom.videoStream = stream.clone();
                        surveyStore.updateRooms(currentRoom);
                        //setLoading(false);
                        dispatch({ type: "finished-loading" });
                    } else {
                        rootState.setError({
                            message: "Camera access is not supported by your browser",
                        });
                    }
                }
            }
        },
        [videoState.loading]
    );

    // check deviation in device angle
    //  useEffect(() => {
    //    if (
    //      videoState.isRecording &&
    //      orientation.highest - orientation.lowest > 30
    //    ) {
    //      recordError.current = "unsteady";
    //      abortController.current.abort();
    //    }
    //  }, [orientation.highest, orientation.lowest, videoState.isRecording]);

    useEffect(() => {
        // displays the SlowDown message if faster than high-threshold, keep displaying until low-threshold is met
        if (videoState.isRecording && rotationRate > RECORDING_SPEED_WARNNG) {
            setSlowDown(true);
            if (rotationRate > RECORDING_SPEED_CUTOFF) {
                recordError.current = "too-fast";
                abortController.current.abort();
                setSlowDown(false);
            }
        } else if (
            videoState.isRecording &&
            rotationRate < RECORDING_SPEED_THRESHOLD
        ) {
            setSlowDown(false);
        }
    }, [videoState.isRecording, rotationRate]);

    useEffect(() => {
        return () => {
            // release global stream array on component clean-up
            streams.forEach((s) => {
                s.getTracks().forEach((t) => {
                    t.enabled = false;
                    t.stop();
                });
            });

            if (surveyStore.currentRoom && surveyStore.currentRoom.videoStream) {
                surveyStore.currentRoom.videoStream.getTracks().forEach((t) => {
                    t.enabled = false;
                    t.stop();
                });
            }
        };
    }, [videoState.displayPreview, videoState.loading]);

    const analyzeVideo = async () => {
        setShowSpinner(true);
        const blob = surveyStore.currentRoom.recordedBlob;
        const formData = new FormData();
        const file = new File([blob], "video.mp4", { type: "video/mp4" });
        formData.append("video", file);
        formData.append("room", surveyStore.currentRoom.roomName);
        formData.append("uuid", surveyStore.surveyId);
        formData.append("step", "video");
        if (surveyStore.currentRoom.roomId) {
            formData.append("room_id", surveyStore.currentRoom.roomId);
        }

        itemsState.items.forEach((item, i) => {
            formData.append(`items_not_moving[]`, item.id);
        });

        rootState.api
            .submitVideo(formData)
            .then((data) => {
                let videoEls = document.querySelectorAll("video");
                if (videoEls.length) {
                    // loop over lingering video elements and clear their src
                    videoEls.forEach((el) => {
                        el.src = null;
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                rootState.setError({ message: e.message });
            });
    };

    useEffect(() => {
        document.body.style.height = "100vh";
        document.body.style.overflowY = "hidden";

        return () => {
            document.body.style.height = "100%";
            document.body.style.overflowY = "initial";
        };
    }, []);
    if (itemsState.showPrompt) {
        return (
            <ItemsNotMoving
                options={JSON.parse(JSON.stringify(surveyStore.inventoryList))}
                items={itemsState.items}
                removeItem={(item) =>
                    dispatchItem({ type: "remove-item", payload: item })
                }
                onChange={(items) =>
                    dispatchItem({ type: "set-items", payload: items })
                }
                onSubmit={() => {
                    dispatchItem({ type: "close-prompt" });
                    analyzeVideo()
                        .catch((e) => {
                            console.log(e);
                            rootState.setError({
                                message: e.message,
                            });
                        })
                        .finally(() => {
                            if (!rootState.error) {
                                const currentRoom = surveyStore.currentRoom;
                                currentRoom.complete = true;
                                currentRoom.streamSrc = "";
                                currentRoom.videoStream = "";
                                currentRoom.recordedBlob = "";
                                surveyStore.updateRooms(currentRoom);
                            }
                            setShowSpinner(false);
                            dispatch({ type: "reset" });

                            surveyStore.setCurrentRoom(null);
                            rootState.setInVideoFlow(false);
                            surveyStore.setRoomOpen(false);
                        });
                }}
            />
        );
    }

    return (
        <>
            {videoState.displayPreview ? (
                <>
                    <PreviewVideo
                        cancelCallback={() => {
                            let currentRoom = surveyStore.currentRoom;

                            resetGyro();
                            dispatch({ type: "finished-loading" });
                            rootState.setRecorder(null);

                            currentRoom.videoStream.getTracks().forEach((t) => t.stop());
                            currentRoom.streamSrc = "";
                            currentRoom.videoStream = "";
                            surveyStore.updateRooms(currentRoom);
                        }}
                        saveCallback={() => {
                            // prompt for items not moving
                            dispatchItem({ type: "show-prompt" });
                        }}
                    />
                    {showSpinner && <Spinner text="Uploading video..." />}
                </>
            ) : (
                <>
                    {rootState.debugMode && (
                        <div
                            onClick={resetGyro}
                            style={{
                                position: "absolute",
                                top: 0,
                                zIndex: 99,
                                backgroundColor: "white",
                            }}
                        >
                            <p>angle:{orientation.arcAngle}</p>
                            <p>angle high:{orientation.highest}</p>
                            <p>angle low:{orientation.lowest}</p>
                            <p>rotation rate: {rotationRate}</p>
                        </div>
                    )}
                    {rootState.debugMode && (
                        <MotionIndicator rotationRate={rotationRate} />
                    )}
                    <SlowDown slowDown={slowDown} />
                    <StyledVideo
                        id="video"
                        ref={videoRef}
                        muted={true}
                        autoPlay
                        loop
                        playsInline
                    ></StyledVideo>
                    {videoState.loading && (
                        <VideoPlaceholder
                            loading={videoState.loading ? videoState.loading : ""}
                        />
                    )}
                    {rootState.gyroEnabled && <Crosshairs angle={orientation.arcAngle} />}
                    <CancelBtn
                        onClick={() => {
                            rootState.setRecorder(null);
                            rootState.setInVideoFlow(false);
                            if (abortController.current) {
                                abortController.current.abort();
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faX} />
                    </CancelBtn>
                    <RecordStopButton
                        isRecording={videoState.isRecording}
                        handleClick={recordVideo}
                        isPlaying
                    />
                    <TimeRecording seconds={time} />
                </>
            )}

            {!videoState.confirmedPrompt && (
                <Prompt
                    customStyles={promptStyles}
                    singleBtn={true}
                    leftBtnText="START RECORDING"
                    leftBtnClick={() => {
                        dispatch({ type: "prompt-record" });
                        recordVideo();
                    }}
                    closeBtnClick={() => dispatch({ type: "acknowledge-prompt" })}
                    leftBtnDisabled={videoState.loading}
                >
                    <>
                        <PromptHeader>READY TO BEGIN?</PromptHeader>
                        <PromptText>Room should be well lit.</PromptText>
                        <PromptText>Record each item only once.</PromptText>
                        <PromptText>Each video can only be up to 10s long.</PromptText>
                        <PromptText>Let us know if any items aren't moving.</PromptText>
                    </>
                </Prompt>
            )}
            {videoState.displayFlatLine && (
                <Prompt
                    customStyles={promptStyles}
                    singleBtn={true}
                    leftBtnClick={() => {
                        dispatch({ type: "reset" });
                    }}
                    leftBtnText="Close"
                    closeBtnClick={() => {
                        dispatch({ type: "reset" });
                    }}
                >
                    <>
                        <PromptHeader>Let's try that again</PromptHeader>
                        <PromptText>
                            In order for us to get an accurate reading, you need to record in
                            a slow, flat motion.
                        </PromptText>
                    </>
                </Prompt>
            )}
            {videoState.displaySlowDown && (
                <Prompt
                    singleBtn={true}
                    leftBtnClick={() => {
                        dispatch({ type: "reset" });
                    }}
                    leftBtnText="Close"
                    closeBtnClick={() => {
                        dispatch({ type: "reset" });
                    }}
                >
                    <>
                        <PromptHeader>Slow Down</PromptHeader>
                        <PromptText>
                            In order for us to get an accurate reading, you need to record in
                            a slow, smooth manner.
                        </PromptText>
                    </>
                </Prompt>
            )}
        </>
    );
}

export default observer(Video);

function TimeRecording({ seconds }) {
    // formats time as `mm:ss`
    const secs = seconds % 60;
    const mins = (seconds - (seconds % 60)) / 60;
    const time = `${mins < 10 ? "0" + mins : mins}:${secs < 10 ? "0" + secs : secs
        }`;

    return <Time>{time}</Time>;
}

function videoReducer(state, action) {
    switch (action.type) {
        case "finished-loading":
            return {
                loading: false,
                confirmedPrompt: false,
                displayPreview: false,
                isRecording: false,
            };
        case "start-recording":
            return {
                loading: false,
                displayPreview: false,
                confirmedPrompt: true,
                isRecording: true,
            };
        case "finished-recording":
            return {
                loading: false,
                displayPreview: true,
                confirmedPrompt: true,
                isRecording: false,
            };
        case "cancel-recording":
            return {
                loading: false,
                displayPreview: false,
                isRecording: false,
                confirmedPrompt: true,
            };
        case "acknowledge-prompt":
            return {
                confirmedPrompt: true,
                loading: false,
                displayPreview: false,
                isRecording: false,
            };
        case "prompt-record":
            return {
                confirmedPrompt: true,
                loading: false,
                displayPreview: false,
                isRecording: true,
            };
        case "start-upload":
            return { ...state, showSpinner: true };
        case "end-upload":
            return { ...state, showSpinner: false };
        case "reset":
            return {
                confirmedPrompt: false,
                loading: true,
                displayPreview: false,
                isRecording: false,
            };
        case "slow-down":
            return {
                displaySlowDown: true,
                confirmedPrompt: true,
                loading: false,
                displayPreview: false,
                isRecording: false,
            };
        case "flat-line":
            return {
                displayFlatLine: true,
                confirmedPrompt: true,
                loading: false,
                displayPreview: false,
                isRecording: false,
            };
        default:
            return state;
    }
}

function itemsReducer(state, action) {
    switch (action.type) {
        case "show-prompt":
            return {
                showPrompt: true,
                items: [],
            };
        case "set-items":
            return { showPrompt: true, items: action.payload };
        case "add-item":
            return {
                ...state,
                items: [
                    ...state.items,
                    { id: action.payload.id, item: action.payload.item },
                ],
            };
        case "remove-item":
            return {
                ...state,
                items: state.items.filter((i) => i.id !== action.payload.id),
            };
        case "close-prompt":
            return { ...state, showPrompt: false };
        default:
            return state;
    }
}
